import store from "@/store";
import _ from "lodash";
import transferData from "@/components/TransferData";

export default {
  data() {
    console.log(this.$store.state);
    return {
      update: true,
      transferShow: false
    }
  },

  computed: {
    lang: {
      get() {
        return this.$store.state.Public.lang.schedule;
      }
    },

    quoteData: {
      get() {
        return this.$store.state.schedule.myQuote.data;
      }
    },

    originalQuoteData: {
      get() {
        return this.$store.state.schedule.myQuote.original;
      }
    },

    scheduleData: {
      get() {
        return this.$store.state.schedule.edit.data;
      }
    },

    scheduleList: {
      get() {
        return this.$store.state.schedule.edit.ScheduleList;
      }
    },

    point: {
      get() {
        return this.$store.state.schedule.point;
      }
    },

    transferList: {
      get() {
        return [];
      }
    },

    formatPrice() {
      return (priceList) => {
        if (priceList) {
          return $.map(priceList, (item) => {
            let lable = item.name + item.listPrice + "元";
            return {
              value: item.uid,
              label: lable
            }
          });
        }
      }
    },
  },

  methods: {
    updateData() {
      let point = this.formatPoint(this.quoteData);
      let original = this.formatPoint(this.originalQuoteData);

      store.dispatch("schedule/updateDayListPoint", point);
    },

    selectOpint() {
      let point = this.formatPoint(this.quoteData);
      let original = this.formatPoint(this.originalQuoteData);

      this.update = !(_.isEqual(point, original));
    },

    formatPoint(ary) {
      let point = [];
      _.each(ary, (item) => {
        _.each(item.point, (item) => {
          point.push(item);
        });
      });

      return point;
    },

    transferData() {
      this.transferShow = !this.transferShow
    },

    transferFunc(data) {
      // console.log(data);
      // console.log(this.$route.params.Schedule);
      const temp = {
        stroke: data,
        schedule: this.$route.params.Schedule,
      };

      this.$store.dispatch("travel/transferToTravel", temp).then((data) => {
        this.transferShow = false;
      });
    },
  },

  mounted() {
    this.$store.dispatch("schedule/getQuote", this.$route.params.Schedule);
    this.$store.dispatch("schedule/getScheduleDay", this.$route.params.Schedule);
    this.$store.dispatch("schedule/getPoint", this.$route.params.Schedule);
  },

  components: {
    transferData
  }
};
