<template>
  <div class="row no-gutters">
    <div class="d-flex flex-column p-0 p-md-1">
      <div class="h-100 card shadow rounded m-0">
        <div class="card-header bg-dagee-blue p-2">
          <div class="d-flex align-items-center justify-content-between">
            <router-link class="text-white" :to="{name:'index'}" tab="a">
              {{lang.backpage}}
            </router-link>
          </div>
        </div>
        <div class="card-body p-2 overflow-auto">
          <table class="table table-sm table-bordered" v-for="(day, index) in quoteData"
            :key="index">
            <caption>第 {{index+1}} 天</caption>
            <thead>
              <tr>
                <th>類型</th>
                <th>名稱</th>
                <th>價錢</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(point, key) in day.point" :key="'point__' + key">
                <td style="width:100px">{{point.tagMethod}}</td>
                <td>{{point.title}}</td>
                <td style="width:200px">
                  <span v-if="!day.elePoint[point.place_id].length > 0">尚未詢價</span>

                  <v-select :options="formatPrice(day.elePoint[point.place_id].price)"
                    :reduce="data => data.value" label="label" v-model="point.pricekid"
                    @input="selectOpint(point, day.elePoint[point.place_id].price)"
                    v-if="day.elePoint[point.place_id].length>0">
                  </v-select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-sm btn-primary mx-1" @click="transferData()">轉移</button>
          <button class="btn btn-sm btn-success mx-1" @click="updateData()"
            v-if="update">儲存</button>
          <button class="btn btn-sm btn-info mx-1">報價單</button>
        </div>
      </div>
    </div>
    <div v-if='transferShow'>
       <transferData :open.sync="transferShow" :list="transferList" :scheduleData="scheduleData"
      :stroke="scheduleList" :pointData="point" @func="transferFunc"></transferData>
    </div>
   
  </div>
</template>
 
<script>
  import ctrl from "./Quote.js";
  export default ctrl;

</script>
